import { SearchTextInput } from "@components/SearchTextInput";
import { Cell } from "@components/layout/GridSystem";
import { css } from "@emotion/react";

import { MediaSize, cssForMediaSize } from "src/theme/mediaQueries";
import { spacing } from "src/theme/spacing";

const searchbarCellCss = [
  css`
    justify-self: center;
    order: 10; /* move to end of element */
    margin-top: ${spacing.m};
  `,
  cssForMediaSize({
    min: MediaSize.MEDIUM,
    css: css`
      order: inherit;
      margin-top: 0;
      max-width: 50%;
    `,
  }),
];

export const SearchBar = () => {
  return (
    <Cell css={searchbarCellCss} numCols={6} numColsMedium="FILL">
      <SearchTextInput data-tname="searchBar" aria-label="Header search" />
    </Cell>
  );
};
